/* eslint-env browser */

import engine from 'store/src/store-engine';
import localStorage from 'store/storages/localStorage';
import cookieStorage from 'store/storages/cookieStorage';

const ARTICLE_STORAGE_KEY = 'previousArticle';
const PAGE_STORAGE_KEY = 'previousPage';
const PAGE_TYPES = ['Page', 'Frontpage', 'Listing'];

const DEFAULT_CONFIG = {
  objectType: undefined
};

function getDomainFromUrl(url) {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  return a.hostname;
}

function urlExists(url) {
  return Boolean(url) && url.length > 0;
}

function userCameFromTheSameDomain(referrer, url) {
  return urlExists(referrer) && urlExists(url) &&
    getDomainFromUrl(referrer) === getDomainFromUrl(url);
}

export default class LocalHistoryPlugin {
  constructor(tracker, config = {}) {
    this.tracker = tracker;
    this.store = engine.createStore([localStorage, cookieStorage]);
    this.config = Object.assign({}, DEFAULT_CONFIG, config);
    this.update();
  }

  update() {
    this.updateTrackerState();
    this.updatePersistedHistory();
  }

  /**
   * Read previous state from local storage and update tracker state
   */
  updateTrackerState() {
    this.tracker.update({
      object: {
        custom: new Promise((resolve, reject) => {
          this.tracker.evaluateEventInputs().then((event) => {
            const noHistory = {
              'spt:url': event.object.url,
              'spt:referrer': '',
              'spt:isReferrerArticle': false,
              'spt:referrerId': '',
              'spt:previousReferrer': null,
              'spt:referringImpression': null
            };

            if (!userCameFromTheSameDomain(event.origin.url, event.object.url)) {
              // Only use the persisted history of the referrer is from the same page
              return resolve(noHistory);
            }

            const { type } = this.config.objectType ? {
              type: this.config.objectType
            } : event.object;

            const referringPage = this.store.get(PAGE_STORAGE_KEY);
            const referringArticle = this.store.get(ARTICLE_STORAGE_KEY);
            const referrer = referringPage || referringArticle || {};
            if (type === 'Article' && referrer.url && referrer.url === event.origin.url) {
              return resolve({
                'spt:url': event.object.url,
                'spt:referrer': event.origin.url,
                'spt:isReferrerArticle': Boolean(referringArticle),
                'spt:referrerId': referrer ? referrer.id : event.origin.url,
                'spt:previousReferrer': referrer ? referrer.referrer : null
              });
            } else if (PAGE_TYPES.indexOf(type) !== -1) {
              return resolve({
                'spt:url': event.object.url,
                'spt:referrer': event.origin.url
              });
            }
            return resolve(noHistory);
          }).catch(reject);
        })
      }
    }, true);
  }

  /**
   * Persist current tracker state to local storage for use later.
   */
  updatePersistedHistory() {
    this.tracker.evaluateEventInputs().then((event) => {
      const { type } = event.object;
      if (type === 'Article') {
        this.updatePersistedArticleHistory();
      } else if (PAGE_TYPES.indexOf(type) !== -1) {
        this.updatePersistedPageHistory();
      }
    });
  }

  updatePersistedPageHistory() {
    this.store.set(ARTICLE_STORAGE_KEY, null);
    this.tracker.evaluateEventInputs().then((event) => {
      this.store.set(PAGE_STORAGE_KEY, {
        url: event.object.url,
        referrer: event.origin.url,
        id: event.object.id
      });
    });
  }

  updatePersistedArticleHistory() {
    this.store.set(PAGE_STORAGE_KEY, null);
    this.tracker.evaluateEventInputs().then((event) => {
      this.store.set(ARTICLE_STORAGE_KEY, {
        url: event.object.url,
        referrer: event.origin.url,
        id: event.object.id
      });
    });
  }
}
